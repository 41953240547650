<template>
	<div class="callthepolice">
		<div class="callthepolice-T">
			<div class="callthepoliceL">
				<Breadcrumb></Breadcrumb>
				<div class="RightsManagement-searchs">
					<div class="RightsManagement-searchs1">
						<el-date-picker v-model="TimeData" type="datetimerange" @change="TimeChang" range-separator="至"
							style="width: 360px;"
							:picker-options="pickerOptions1"
							:clearable="false"
							value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
						<el-select v-model="pages.T_handle" @change="selectChang" style="margin: 0 20px;width: 100px;" placeholder="请选择查询条件">
							<el-option label="默认" value="1"></el-option>
							<el-option label="全部记录" value="2"></el-option>
							<el-option label="未处理" value="3"></el-option>

						</el-select>
					</div>
					<div class="callthepoliceit-mai1-btn">
						<el-button type="primary" icon="el-icon-search" @click="showhistory">更多查询</el-button>
					</div>
				</div>
			</div>
			<div class="callthepoliceR">
				<div class="RightsManagement-searchsMai">
					<el-button v-permission="'Company:Warning:Excel'" type="warning"
						@click="DowDataExcel">导出Excel</el-button>
					<!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
				</div>
			</div>
		</div>
		<div class="callthepolice-B">
			<el-table ref="multipleTable" v-loading="loading" :data="tableData" border size="mini"
				:row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column label="类型" width="120">
					<template slot-scope="scope" show-overflow-tooltip>{{ scope.row.T_tp_name }}</template>
				</el-table-column>
                <el-table-column label="公司名称" width="160">
					<template slot-scope="scope" show-overflow-tooltip>{{ scope.row.T_pid_name }}</template>
				</el-table-column>
                
				<el-table-column prop="T_sn" label="SN" width="150" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="T_DS_name" label="名称" width="150" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="T_Remark" label="内容" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="T_Text" label="处理措施" width="100" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="T_Ut" label="时间" width="150" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="操作" width="250" fixed="right">
					<template slot-scope="scope">
						<el-button v-permission="'Company:Warning:Handle'" size="small"
							@click="SetChuli(scope.row)">处理</el-button>
						<el-button v-permission="'Company:Warning:Del'" size="small"
							@click="SetDel(scope.row)">删除</el-button>
						<el-button v-permission="'Company:Warning:Record'" size="small"
							@click="Setjilu(scope.row)">记录</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-button v-permission="'Company:Warning:DatchHandle'" @click="ResDateFilter"
				style="margin-top: 10px;">批量处理</el-button>
			<div style="margin-top: 50px;">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page="pages.page" :page-size="pages.page_z" :page-sizes="[10, 50, 100, 500, 1000]"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-drawer title="更多查询" :visible.sync="drawer" direction="rtl" size="50%" :append-to-body="true">
			<div style="padding:0 20px;">
				<el-form ref="form" :model="pages" label-width="80px">
					<!-- <el-form-item label="查询条件">
						<el-select v-model="pages.T_history" @change="selectChang" placeholder="请选择查询条件">
							<el-option label="最近40天" value="0"></el-option>
							<el-option label="历史记录" value="1"></el-option>
						</el-select>
					</el-form-item> -->
					<!-- <el-form-item :label="pages.T_history == 0 ? '最近40天' : '历史记录'">
						<el-date-picker v-model="TimeData" type="datetimerange" @change="TimeChang" range-separator="至"
							:picker-options="pages.T_history == 0 ? pickerOptions : pickerOptions1"
							value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item> -->
					<el-form-item label="名称查询">
						<inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入名称"></inputs>
					</el-form-item>
					<el-form-item label="报警分类">
						<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
							@change="handleCheckAllChange">全选</el-checkbox>
						<el-checkbox-group v-model="pages.T_tp" @change="handleCheckedCitiesChange">
							<el-checkbox v-for="item, index in dataPowerAll" :label="item.Id" :key="index">{{ item.T_name }}
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<!-- <el-form-item label="状态查询">
						<el-radio-group v-model="pages.T_handle">
							<el-radio label="1">默认</el-radio>
							<el-radio label="2">全部记录</el-radio>
							<el-radio label="3">未处理</el-radio>
						</el-radio-group>
					</el-form-item> -->
					<el-form-item>
						<el-button type="primary" @click="onSubmit">立即搜索</el-button>
						<el-button @click="drawer = false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
		<el-drawer title="处理记录" :visible.sync="drawerJiLu" direction="rtl" size="50%" :append-to-body="true">
			<div style="padding:0 20px;">
				<el-table ref="singleTable" :data="tableData1" highlight-current-row style="width: 100%">
					<template slot="empty">
						<noData></noData>
					</template>
					<el-table-column type="index" width="50" label="序号">
					</el-table-column>
					<el-table-column label="详细信息">
						<template slot-scope="scope">
							{{ scope.row }}
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-drawer>
		<el-dialog title="提示" :visible.sync="dialogChuli" width="30%" :append-to-body="true">
			<el-form ref="chuliPage" :model="chuliPage" label-width="100px" label-position="top">
				<el-form-item label="处理报警备注">
					<el-input v-model="chuliPage.T_Text"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="mini" @click="chuliPage.T_Text = '已处理'">已处理</el-button>
					<el-button type="primary" size="mini" @click="chuliPage.T_Text = '天气原因'">天气原因</el-button>
					<el-button type="primary" size="mini" @click="chuliPage.T_Text = '开空调'">开空调</el-button>
					<el-button type="primary" size="mini" @click="chuliPage.T_Text = '开除湿机除湿'">开除湿机除湿</el-button>
					<el-button type="primary" size="mini" @click="chuliPage.T_Text = '测试'">测试</el-button>

				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogChuli = false">取 消</el-button>
				<el-button type="primary" @click='onSubmitFun'>确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {
	CompanyWarningList,
	WTall,
	DevicEdit,
	DevicDel,
	DevicExcel
} from "@/api/companyCallthepolice.js"
import Axios from 'axios'
import fileDownload from 'js-file-download';
import { formatDate } from '../../utils/Times.js'
export default {
	data() {
		return {
			showChu:'',
			chuliPage:{
				T_id: '',
				T_Text: '',
				T_time:'',
				T_history:''
			},
			dialogChuli:false,
			checkAll: true,
			isIndeterminate: false,
			Excelobj: {},
			loading: true,
			TimeData: [],
			dataPowerAll: [], //报警选择
			pages: {
				T_name: '',
				T_tp: [],
				T_handle: '1', //1 默认 2 全部记录 3未处理
				Time_start: '',
				Time_end: '',
				T_admin: 0,
				T_history: '0', //0 40天 1 历史数据
				page: 1,
				page_z: 10,
			},
			total: 0,
			pages1: {//表格数据
				T_snid: '',
				Time_start: '',
				Time_end: '',
				page: 1,
				page_z: 10,
			},
			pickerOptions1: {//选择当前时间的前后6个月
				onPick: ({
					maxDate,
					minDate
				}) => {
					this.beginDate = minDate && minDate.getTime();
					if (maxDate) {
						this.endDate = "";
					}
				},
				disabledDate: (time) => {
					const minTime = new Date(this.beginDate).setMonth(
						new Date(this.beginDate).getMonth() - 6
					);
					const maxTime = new Date(this.beginDate).setMonth(
						new Date(this.beginDate).getMonth() + 6
					);
					return this.beginDate
						? time.getTime() < minTime || time.getTime() > maxTime
						: false;
				},
			},
			pickerOptions: {
				disabledDate(time) {
					let AtTime = new Date().getTime(); //获取此时的时间戳
					return (
						time.getTime() < AtTime - (86400000 * 40) //小于当前时间40天的全部disabled掉
						||
						time.getTime() > AtTime //大于当前时间的全部disabled掉
					);
				}
			},
			drawer: false,
			drawerJiLu: false, //记录弹窗
			tableData: [],
			tableData1: [],
			multipleSelection: [],
			dialogVisible: false,
		}
	},
	mounted() {
		this.pages = {...this.pages,...this.$route.query}
		this.setTimefn()
		this.getWarningListApi()
	},
	methods: {
		setTimefn() { //获取当天0点与当前时间 
			const now = new Date();  
			const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0); 
			const min = midnight.getTime() - (86400000*40)//今天0点时间戳，
			const max = now.getTime(); //当前时间戳

			this.pages.Time_start = formatDate(min)
			this.pages.Time_end = formatDate(max)

			this.TimeData = [formatDate(min),formatDate(max)]

			this.$emit("pick", this.TimeData)
		}, 
		showhistory() {
			this.drawer = true
			this.SetWarning()
		},
		handleCheckAllChange(e) {
			e ? this.dataPowerAll.forEach(item => { this.pages.T_tp.push(item.Id) }) : this.pages.T_tp = []
			this.isIndeterminate = false;//全选状态
		},
		handleCheckedCitiesChange(value) {
			////console.log('danxuan', value)
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.dataPowerAll.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.dataPowerAll.length;
		},
		DowDataExcel() {
			if (this.tableData == null) {
				this.$message.error('当前导出没有数据哦！')
				return
			} else {
				const loading = this.$loading({
					lock: true,
					text: '正在整理数据，请稍后...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				DevicExcel(this.Excelobj).then(res => {
					////console.log('导出exc', res)
					loading.close()
					Axios({
						method: 'get',
						url: res.data.Data,
						responseType: 'blob'
					}).then(resBlob => {
						fileDownload(resBlob.data, `${new Date().getTime()}.xlsx`);
					});
				})
			}
		},
		tableRowClassName({ row, rowIndex }) {
			////console.log('表格', row, rowIndex)
			if (row.T_State === 3) {
				return 'warning-row';
			} else {
				return '';
			}

		},
		async SetWarning() {
			var pwAll = await this.GetWTallApi()
			if (pwAll.data.Code === 200) {
				this.checkAll = true;//全选状态
				this.isIndeterminate = false
				var arr = pwAll.data.Data
				this.pages.T_tp = []
				this.dataPowerAll = arr
				arr.forEach(item => {
					this.pages.T_tp.push(item.Id)
				})
				////console.log('48888', this.checkAll)
			}
		},
		GetWTallApi() { //获取报警权限
			return new Promise(resolve => {
				WTall({}).then(res => {
					resolve(res)
				})
			})
		},
		getWarningListApi() {
			this.loading = true
			var obj = {
				...this.pages
			}
			obj.T_tp.length != 0 ? obj.T_tp = "T" + obj.T_tp.join('|T') + "|" : obj.T_tp = ''
			this.Excelobj = obj
			return new Promise(resolve => {
				CompanyWarningList(obj).then(res => {
					if (res.data.Code === 200) {
						this.loading = false
						this.total = res.data.Data.Num
						this.tableData = res.data.Data.Data
						resolve(res.data)
					}
				})
			})
		},

		Setjilu(e) { //记录
			////console.log(e)
			this.tableData1 = e.T_Log
			this.drawerJiLu = true
		},
		SetDel(obj) { //删除
			////console.log('删除', obj)
			this.$confirm('此操作将执行删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true
			}).then(() => {
				DevicDel({
					T_id: obj.Id,
					T_time: obj.T_Ut,
					T_history: obj.T_history
				}).then(res => {
					if (res.data.Code === 200) {
						this.getWarningListApi()
						this.$message.success('操作成功，已删除')
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		SetChuli(e) { //处理按钮
			this.chuliPage = {
				T_id: e.Id,
				T_Text: '',
				T_time: e.T_Ut,
				T_history: e.T_history
			}
			this.showChu = 'rad'
			this.dialogChuli = true
		},
		onSubmitFun(){
			if (this.showChu=='all') {
				this.asyncPro()
			} else {
				this.subChuli()
			}
		},
		async subChuli(){
			
			var val = await this.setDevicEditApi(this.chuliPage)
			if (val.data.Code == 200) {
				this.pages.page = 1
				this.getWarningListApi()
				this.$message.success('处理成功');
				this.dialogChuli = false
				this.chuliPage.T_Text = ''
			}
		},
		ResDateFilter() { //批量处理
			if (this.multipleSelection.length == 0) {
				this.$message.error('请选择至少一项')
			} else {
				this.showChu = 'all'
				this.dialogChuli = true
			}

		},
		async asyncPro() {
			var obj = {
				T_id: null,
				T_Text: this.chuliPage.T_Text
			}
			var k = 0
			for (let i = 0; i < this.multipleSelection.length; i++) {
				obj.T_id = this.multipleSelection[i].Id
				let objs = await this.setDevicEditApi(obj);
				if (objs) {
					k = k + 1
				}
				if (this.multipleSelection.length == k) {
					this.$message.success('批量处理完成')
					this.pages.page = 1
					this.getWarningListApi()
					this.dialogChuli = false
					this.chuliPage.T_Text = ''
				}
			}
		},
		setDevicEditApi(obj) {
			return new Promise(resolve => {
				DevicEdit(obj).then(res => {
					resolve(res)
				})
			})
		},
		selectChang() { //切换查询条件select
			this.getWarningListApi()
		},
		TimeChang(e) { //选择完时间确定按钮
			if(e){
				this.pages.Time_start = e[0]
				this.pages.Time_end = e[1]
			}else{
				this.setTimefn()
			}
			this.getWarningListApi()
		},
		onSubmit() { //搜索按钮
			this.pages.page = 1
			this.getWarningListApi()

			this.drawer = false
		},
		handleSizeChange(e) { //每页多少条
			////console.log('每页多少条', e)
			this.pages.page_z = e
			this.getWarningListApi()
		},
		handleCurrentChange(e) { //跳转页
			////console.log('跳转页', e)
			this.pages.page = e
			this.getWarningListApi()
		},


		// toggleSelection(rows) {
		// 	if (rows) {
		// 		rows.forEach(row => {
		// 			this.$refs.multipleTable.toggleRowSelection(row);
		// 		});
		// 	} else {
		// 		this.$refs.multipleTable.clearSelection();
		// 	}
		// },
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		inputValue(e) {
			this.pages.T_name = e
			this.pages.page = 1
			this.getWarningListApi()
		},
	}
}
</script>
<style lang="scss">
.el-table .warning-row {
	background: oldlace;
}

.callthepolice {
	.callthepolice-T {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: rgba(#fff, 1);
		height: 70px;
		padding: 15px 10px;
		box-shadow: 5px 5px 10px -10px #000;

		.callthepoliceL {
			display: flex;
			align-items: center;

			.RightsManagement-searchs {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 20px;

				.callthepoliceit-mai1-btn {}

				.RightsManagement-searchs1 {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.RightsManagement-searchsMai {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}

	.callthepolice-B {
		background: rgba(#fff, 1);
		margin: 10px;
		padding: 20px 30px;
	}
}
</style>
