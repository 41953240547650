// 登录接口
import {
	axios
} from '../utils/http.js'

const WarningApi = {
	CompanyWarningList: '/v3/CompanyWarning/List2',//权限管理列表
	WTall: '/v3/WarningType/Power_List',//报警类型列表
	DevicEdit: '/v3/DeviceWarning/Edit',//处理
	DevicDel: '/v3/DeviceWarning/Del',//删除
	DevicExcel: '/v3/CompanyWarning/ToExcel2',//导出表格
	
}
export function DevicExcel(data) {
	return axios({
		url: WarningApi.DevicExcel,
		method: 'post',
		data:data
	})
}
//删除
export function DevicDel(data) {
	return axios({
		url: WarningApi.DevicDel,
		method: 'post',
		data:data
	})
}
//处理
export function DevicEdit(data) {
	return axios({
		url: WarningApi.DevicEdit,
		method: 'post',
		data:data
	})
}
//报警类型列表
export function WTall(data) {
	return axios({
		url: WarningApi.WTall,
		method: 'post',
		data:data
	})
}
//权限树选择
export function CompanyWarningList(data) {
	return axios({
		url: WarningApi.CompanyWarningList,
		method: 'post',
		data:data
	})
}
